import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect () {
    this.loadDefaults();
  }

  copyButtonHandler = (e) => {
    const button = e.target;
    const { url, success } = button.dataset;
    const originalText = button.innerHTML;
    // create a dummy input to copy from
    const dummyInput = document.createElement('textarea');
    dummyInput.value = url;
    document.body.appendChild(dummyInput);
    dummyInput.select();
    document.execCommand('copy');
    document.body.removeChild(dummyInput);

    // update the button text for two seconds
    button.innerHTML = success;
    button.disabled = true;
    setTimeout(() => {
      button.innerHTML = originalText;
      button.disabled = false;
    }, 2000);
  };

  copyTextElement = (copyButton) => {
    copyButton.addEventListener('click', this.copyButtonHandler);
  };

  copyTextDisconnect = (copyButton) => {
    copyButton.removeEventListener('click', this.copyButtonHandler);
  };

  loadDefaults () {
    const inputs = document.querySelectorAll('.elective-item .js-copy-text');

    inputs.forEach((copyButton) => {
      if (copyButton) this.copyTextElement(copyButton);

      return () => {
        if (copyButton) this.copyTextDisconnect(copyButton);
      };
    });
  }
}
